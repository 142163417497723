import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            chita
          }
        }
      }
    `
  )

  const metaDescription = "Проститутки Читы расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Читы готовы ко встрече в приватной обстановке." || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://vipdosug.org/prostitutki-chiti/"/>
      <link rel="alternate" href="https://vipdosug.org/prostitutki-chiti/" hreflang="ru"/>
      <link rel="amphtml" href="https://vipdosug.org/prostitutki-chiti/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
      <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="vipdosug.org"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.chita}/>
      <meta property="og:url" content="https://vipdosug.org/prostitutki-chiti/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.chita}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.chita}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://vipdosug.org/vipdosug.atom" title="Проститутки России, лучшие индивидуалки | VipDosug.org"/>
      <script type="application/ld+json">
  {`
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": "https://vipdosug.org/",
    "name": "VipDosug.Org",
    "sameAs": [
        "https://www.youtube.com/@dosugcz8671",
        "https://flipboard.com/@dosug",
        "https://vk.com/dosug.cz_ru",
        "https://t.me/DosugOfficialle",
        "https://twitter.com/DosugczOfficial",
        "https://www.facebook.com/dosugru.net",
        "https://ru.pinterest.com/dosugru/"
    ]
}
  `}
</script>
<script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org",
    "author": {
        "@type": "WebPage",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/"
    },
    "@type": "Article",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://vipdosug.org/prostitutki-chiti/"
    },
    "headline": "Проститутки Читы, лучшие индивидуалки | VipDosug.org",
    "description": "Проститутки Читы расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Читы готовы ко встрече в приватной обстановке.",
    "articleBody": "Этот сайт посвящен тому, как быстро и без лишних проблем найти, выбрать и заказать проститутку Читы. Вообще Чита прекрасный город, в котором масса развлечений на любо вкус, но если вам нестерпимо захотелось секса, то вызов проститутки – самый удобный вариант. Индивидуалки Читы умеют все в плане секса и являются настоящими профессионалками своего дела. Это просто виртуозки орального, анального или классического секса. Индивидуалки Читы доводили до оргазма даже самых требовательных любовников, и от них еще никто не уходил неудовлетворенным. Кроме того, они работают круглосуточно и могут примчаться к вам в любое время дня и ночи. Если вам негде встретить эту жрицу любви, то она сама пригласит вас в свои апартаменты или подъедет в любо удобное для вас место, будь, то сауна, номер в отеле или съемная квартира. Хватит мечтать о сексе. Пора реализовывать свои желания. Просто звоните по номер....ведь проститутки Читы, пламенные и очаровательные любовницы, уже с нетерпением ждут вашего звонка.",
    "image": [
        "https://vipdosug.org/images/og-chita.png"
    ],
   
    "articleSection": "Развлечения",
    "publisher": {
        "@type": "Organization",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/",
        "logo": {
            "@type": "ImageObject",
            "url": "https://vipdosug.org/images/amp.png",
            "width": 264,
            "height": 59
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo
